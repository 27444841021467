import axios from 'axios';
import qs from 'qs';

const UAParser = require('ua-parser-js');

export const errorHandler = (msg, url, lineNumber, columnNumber, error) => {
	const parser = new UAParser();
	const fields = {
		msg,
		url,
		stack: error.stack,
		user_agent: `{"browser": ${JSON.stringify(parser.getBrowser())}, "os": ${JSON.stringify(
			parser.getOS()
		)}, "device": ${JSON.stringify(parser.getDevice())}}`,
		location: window.location.href,
	};

	axios
		.post(
			'/forum/ajax.php',
			qs.stringify({
				...fields,
				task: 'error_handler_js',
				option: 'optwear\\AjaxRequest',
			})
		)
		.then((result) => {
			return result.data.hidden_error || false;
		});
};
