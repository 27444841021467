import checkMedia from '@Js/scripts/Media';
import SupportsCSS from '@Js/scripts/SupportsCSS';
import commonStyles from '@StylesJson/common.module.json';

const block = 
	`
		<div class="${commonStyles.availabilityCheck}">
			<h2 class="${commonStyles.availabilityCheckText}">
				К сожалению, Ваш браузер слишком устарел, и не поддерживает современные технологии, которые используются в новой теме.<br>
				Обновите Ваш браузер, или используйте более старую тему.
			</h2>
			<button type="button" id="switchOldTheme" class="${commonStyles.button} ${commonStyles.buttonFillPrimary} ${commonStyles.availabilityCheckBtn}">Перейти на старую тему</button>
		</div>
	`     

const availableCheck = () => {
	if (checkMedia('desktop')) {
		if (!SupportsCSS('display', 'grid') || !SupportsCSS('grid-template-columns', '1fr')) {
			document.documentElement.classList.add('document-block');
			document.body.insertAdjacentHTML("afterBegin", block);

			document.getElementById('switchOldTheme').addEventListener('click', () => {
				const current_date = new Date,
				cookie_year = current_date.getFullYear ( ) + 1,
				cookie_month = current_date.getMonth ( ),
				cookie_day = current_date.getDate ( );
				let date = new Date(cookie_year, cookie_month, cookie_day);
				date = date.toUTCString();
				document.cookie = `old_browser=true; path=/forum; expires=${date}`;
				document.location.href = "/forum";
			});

			return false;
		}

		return true;
	}

	return true;
};

export default availableCheck;



