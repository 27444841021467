import axios from 'axios';

const smilesKey = 'SMILES_CACHE';
const smilesRevisionKey = 'SMILES_CACHE_REVISION';




class cacheResource {

	static async toCacheSmiles() {
		const smilesRevisionVersion = 4;
		const getAllSmilesConfig = {
			url: GRAPHQL_API,
			method: 'POST',
			data: {
				query: `query getAllSmiles {
				  Smilies(get_all:true) {
				    code
				    emotion
				    smiley_url
				  }
				}`
			}
		};

		if (
			localStorage.getItem(smilesRevisionKey) !== smilesRevisionVersion.toString() ||
			!localStorage.getItem(smilesKey)
		) {
			const response = await axios.request(getAllSmilesConfig);
			window.localStorage.setItem(smilesRevisionKey, smilesRevisionVersion.toString());
			localStorage.setItem(smilesKey, JSON.stringify(response.data.data.Smilies));
		}
	}
	// прошлый способ для получения спрайта иконок, был заменен на вставку спрайта в документ через gulp

	// static async toCacheSvgSprite() {
	// 	const SVG_SPRITE_REVISION = 65;
	// 	let SVG_SPRITE_DATA;
	//
	// 	if (localStorage.getItem('SVG_SPRITE_REVISION') === SVG_SPRITE_REVISION.toString() && localStorage.getItem('SVG_SPRITE_DATA')) {
	// 		SVG_SPRITE_DATA = localStorage.getItem('SVG_SPRITE_DATA');
	// 	} else {
	// 		const result = await axios({
	// 			url: `/forum/styles/${THEME_NAME}/imageset/icons/icons.svg`,
	// 			method: 'GET',
	// 			responseType: 'text',
	// 		});
	//
	// 		SVG_SPRITE_DATA = result.data;
	// 		window.localStorage.setItem('SVG_SPRITE_REVISION', SVG_SPRITE_REVISION.toString());
	// 		localStorage.setItem('SVG_SPRITE_DATA', SVG_SPRITE_DATA);
	// 	}
	// 	document.body.insertAdjacentHTML('beforeend', SVG_SPRITE_DATA);
	// };
}

export {
	smilesKey,
	smilesRevisionKey,
};

export default cacheResource;