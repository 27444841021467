import cacheResource from '@JsComponents/utils/CacheResource';
import availabilityCheck from '@Js/scripts/availabilityCheck';
import '@Scss/modules/_relative-normalize.scss';
import {isSafari} from '@Js/helpers';
import { errorHandler } from "@Js/bundles/utils/errorHandler";
import {isProduction} from "@Js/scripts/Utils";

if (history.scrollRestoration) {
	history.scrollRestoration = 'auto';
}

// if (/category/gi.test(location.pathname)) {
// 	if (history.scrollRestoration) {
// 		history.scrollRestoration = 'manual';
// 	}
// }


// if (isSafari()) {
// 	const css =
// 			`@media (max-width: 991px) {
// 				select, textarea, input {
// 					font-size: 16px;
// 				}
// 			}`,
// 		body = document.body || document.getElementsByTagName('body')[0],
// 		style = document.createElement('style');
//
// 	style.type = 'text/css';
// 	if (style.styleSheet) {
// 		style.styleSheet.cssText = css;
// 	} else {
// 		style.appendChild(document.createTextNode(css));
// 	}
//
// 	body.appendChild(style);
// }

if (isProduction()) {
	window.onerror = errorHandler;
}

if (availabilityCheck()) {
	Promise.all([
		cacheResource.toCacheSmiles(),
	]).then(() => {
	});
}

